<template>
  <v-row
    v-if="showSummaries"
    class="justify-center"
  >
    <v-col
      v-for="summary in currentSummaries"
      :key="summary.id"
      class="summary"
    >
      <v-card
        :class="`${summary.styles ? summary.styles.background : 'primary'} ${summary.styles ? summary.styles.text : 'white'}--text text-center justify-center`"
      >
        <v-card-title
          primary-title
          class="pb-0"
        >
          <v-col>
            <v-row class="justify-center text-uppercase subtitle-2">
              {{ summary.title }}
            </v-row>

            <v-row
              v-if="summary.subtitle"
              class="justify-center text-uppercase caption"
            >
              {{ getSubtitle(summary) }}
            </v-row>
          </v-col>
        </v-card-title>

        <v-card-text
          :class="`pb-4 ${summary.styles ? summary.styles.value : 'white'}--text headline`"
        >
          <span>
            {{ formatter(summary[summary.calculation || 'total'], summary.format) }}
          </span>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import dates from 'src/utils/dates'
import format from 'src/utils/format'

const { mapGetters } = createNamespacedHelpers('tools')

export default {
  name: 'SimpleSummaryBar',
  computed: {
    ...mapGetters(['currentSummaries']),
    showSummaries() {
      return this.currentSummaries && Object.keys(this.currentSummaries).length
    },
  },
  methods: {
    formatter(value, formatting) {
      if (!formatting) return ''

      return format[formatting](value)
    },
    getSubtitle(summary) {
      const { subtitle } = summary
      const currentMonth = () => dates.getFormattedDate(undefined, 'MMM')
      const setAsMtd = () => subtitle === 'MTD' && `${currentMonth()} Hours`

      return setAsMtd() || subtitle
    },
  },
}
</script>

<style lang="scss" scoped>
.summary {
  max-width: 220px;
}
</style>
